import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link, Strong, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Outdoor Stride Hub
			</title>
			<meta name={"description"} content={"Ми пропонуємо широкий вибір спорядження для зимових видів спорту: лижі, сноуборди, ковзани, спорядження для альпінізму та інші аксесуари."} />
			<meta property={"og:title"} content={"Outdoor Stride Hub"} />
			<meta property={"og:description"} content={"Ми пропонуємо широкий вибір спорядження для зимових видів спорту: лижі, сноуборди, ковзани, спорядження для альпінізму та інші аксесуари."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953828b5e30023ae7020/images/hero-season.webp?v=2024-11-26T12:51:10.628Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				ExpertPathway
			</Override>
		</Components.Header>
		<Section padding="100px 0 100px 0" background="--color-darkL2" quarkly-title="FAQ-7">
			<Text margin="0px 0px 2rem 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Відповіді на поширені запитання
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Як забронювати спорядження?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Все просто! Оберіть необхідні товари на нашому сайті, додайте їх у кошик, виберіть дати оренди та оформіть замовлення.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи можна змінити бронювання?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Так, ви можете змінити або скасувати бронювання за 48 годин до дати початку оренди.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи потрібно залишати заставу?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Так, ми просимо залишити заставу, яка залежить від типу спорядження. Застава повертається одразу після перевірки спорядження.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Як обрати правильний розмір?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Наші консультанти з радістю допоможуть вам підібрати спорядження, враховуючи ваші параметри.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи потрібно чистити спорядження перед поверненням?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Ні, ми самі займаємося чищенням та технічним обслуговуванням спорядження після кожного використання.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Що робити у випадку пошкодження спорядження?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--color-light">
						Якщо ви пошкодили спорядження, повідомте нас якомога швидше. Ми знайдемо оптимальне рішення для обох сторін.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL1" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline2"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ми знаходимося у самому серці Карпат
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Поряд із кращими лижними курортами.{" "}
					<br />
					Обираючи Outdoor Stride Hub, ви отримуєте доступ до високоякісного спорядження і команди, яка готова допомогти у будь-який момент.
				</Text>
				<Link
					href="mailto:blank?hello@company.com"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@outdoorstridehub.gmail
				</Link>
				<Link
					href="tel:1234567899"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					0954691897
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Драгобрат, Закарпатська область, 90630
				</Text>
			</Box>
			<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
				Готові до зими? Забронюйте своє спорядження прямо{" "}
				<br />
				зараз і вирушайте у свою зимову подорож із комфортом!
			</Text>
			<Button
				max-width="300px"
				box-shadow="--m"
				border-radius="3px"
				background="linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);"
				border-color="--color-dark"
				font="--base"
				color="--light"
				type="link"
				text-decoration-line="initial"
				href="/poslugi-ta-cini"
				text-align="center"
				margin="0px auto 0px auto"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Послуги
				</Strong>
			</Button>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});